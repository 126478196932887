import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const TermsAndConditions = ({data: {site, allFile }, location}) => {
    const siteTitle = site.siteMetadata?.title || `Title`
    const terms = allFile.nodes[0].childMarkdownRemark

    return(
        <Layout location={location} title={siteTitle}>
            <div>
                <section
                    dangerouslySetInnerHTML={{ __html: terms.html }}
                    itemProp="termsBody"
                />
            </div>
        </Layout>
    )
}

export default TermsAndConditions

export const Head = () => <Seo title="Zai Duck Wellness Terms and Conditions"/>

export const pageQuery = graphql`
query {
  site {
    siteMetadata {
      title
    }
  }
  allFile(filter: {name: {in: ["terms-and-conditions"]}}) {
    nodes {
      name
      childMarkdownRemark {
        html
      }
    }
  }
}`
